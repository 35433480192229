<template>
  <div class="Rec-page-bg">
    <div class="PracticeShowcase-bg">
      <div class="Pra-item">
        <p class="p_item">{{ $t("COFITList.co_tit1") }}</p>
        <p style="color: black">{{ $t("COFITList.co_tit2") }}</p>
      </div>

      <div class="PraList" v-for="(item, index) in pralist" :key="index">
        <p class="PraList-title">
          {{ $t("COFITList." + item.title) }}
        </p>
        <div class="cnname-list">
          <p :class="['p1 p1_' + index]" style="margin-top: 20px">
            {{ item.cnname }}
          </p>
          <p :class="['p2 p2_' + index]" style="margin-bottom: 20px">
            {{ item.enname }}
          </p>
        </div>
      </div>
    </div>
    <div class="Rec-page-item">
      <p>{{ $t("CompanyList.complist_tit1") }}</p>
    </div>
    <RecPageCom :RecList="fewerCompany" />
    <RecPageCom :RecList="fewerOrganisation" />
    <RecPageCom :RecList="betweenCompany" />
    <RecPageCom :RecList="betweenOrganisation" />
    <RecPageCom :RecList="moreCompany" />
    <RecPageCom :RecList="moreOrganisation" />
  </div>
</template>

<script>
import RecPageCom from "../components/RecPageCom.vue";
import fewerCompany from "../js/Fewer";
import fewerOrganisation from "../js/Fewer";
import betweenCompany from "../js/Between";
import betweenOrganisation from "../js/Between";
import moreCompany from "../js/More";
import moreOrganisation from "../js/More";
export default {
  components: { RecPageCom },
  mixins: [
    fewerCompany,
    fewerOrganisation,
    betweenCompany,
    betweenOrganisation,
    moreCompany,
    moreOrganisation,
  ],
  data() {
    return {
      pralist: [
        {
          title: "co_tit3",
          cnname: "亞洲運動及體適能專業學院有限公司",
          enname: "Asian Academy for Sports & Fitness Professionals Limited",
        },
        {
          cnname: "中國香港跳繩體育聯會有限公司",
          enname: "Hong Kong Rope Skipping Federation, China Limited",
        },
        {
          cnname: "Terra Forma Limited",
          enname: "Terra Forma Limited",
        },

        {
          title: "co_tit4",
          cnname: "Adidas (adidas Sourcing Limited & adidas Hong Kong Limited)",
          enname: "Adidas (adidas Sourcing Limited & adidas Hong Kong Limited)",
        },
        {
          cnname: "藍十字（亞太）保險有限公司",
          enname: "Blue Cross (Asia-Pacific) Insurance Limited",
        },
        {
          cnname: "葛蘭素史克有限公司",
          enname: "GlaxoSmithKline Limited",
        },

        {
          title: "co_tit5",
          cnname: "捷成洋行有限公司",
          enname: "Jebsen and Company Limited",
        },
        {
          cnname: "香港中華煤氣有限公司",
          enname: "The Hong Kong and China Gas Company Limited",
        },
        {
          cnname: "九龍巴士（一九三三）有限公司",
          enname: "The Kowloon Motor Bus Company (1933) Limited",
        },
        {
          cnname: "香港大學",
          enname: "The University of Hong Kong",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.Rec-page-bg {
  .Rec-page-item {
    margin: 30px;
    p {
      color: black;
      font-size: 17px;
      // font-weight: 600;
      font-family: "PingFang-Bold";
    }
  }
  .PracticeShowcase-bg {
    margin-bottom: 50px;
    .Pra-item {
      margin: 30px;
      .p_item {
        color: black;
        font-size: 17px;
        font-family: "PingFang-Bold";
        margin-bottom: 40px;
      }
    }
    .PraList {
      .PraList-title {
        margin: 30px;
        display: inline;
        line-height: 60px;
        background-color: rgb(217, 217, 217);
        color: black;
      }
      width: 80%;
      margin-left: 10%;
      .cnname-list {
        min-height: 70px;
        border: 2px solid rgb(97, 96, 96);
        border-bottom: none;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        flex-direction: column;
        .p1_2,
        .p1_3 {
          display: none;
        }
      }
    }
    :nth-child(3),
    :nth-child(4),
    :nth-child(6),
    :nth-child(7),
    :nth-child(9),
    :nth-child(10),
    :nth-child(11) {
      .PraList-title {
        display: none !important;
      }
    }
    :nth-child(4),
    :nth-child(7),
    :nth-child(11) {
      .cnname-list {
        border: 2px solid rgb(97, 96, 96);
      }
    }
  }
}
</style>